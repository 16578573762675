<template>
  <v-app id="login-container">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 lg4>
          <v-card class="pa-3">
            <v-card-text>
              <div class="layout justify-center">
                <img src="../../public/img/icons/kby.png" alt="KBY" width="120" height="75">
              </div>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-text-field
                  v-model="loginObj.email"
                  :label="$t('lang.email')"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  required
                  :rules="[rules.requiredValue, rules.emailMatch]"
                />

                <v-text-field
                  id="password"
                  v-model="loginObj.password"
                  name="password"
                  prepend-icon="mdi-key"
                  :label="$t('lang.password')"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.requiredValue]"
                  @click:append="showPassword = !showPassword"
                  @keyup.enter.native="handleUserLogin"
                />
                <v-select
                  v-model="lang"
                  :items="listofLang"
                  item-text="name"
                  item-value="id"
                  prepend-icon="mdi-google-translate"
                  :label="$t('lang.language')"
                  :rules="[rules.requiredValue]"
                  @change="changeLang"
                >
                  <template slot="item" slot-scope="data" class="list_item">
                    <template>
                      <v-list-item-action>
                        <span :class="data.item.icon" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-select>
                <v-checkbox
                  v-model="rememberMe"
                  :label="$t('lang.rememberMe')"
                  color="primary"
                />

              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="deep-orange"
                dark
                @click="handleUserLogin"
              >
               {{ $t("lang.login") }}
              </v-btn>
              <v-spacer></v-spacer>
              <a @click="$router.push('/forgotpassword')"> {{ $t('lang.forgotPassword') }} </a>
            </v-card-actions>
            <v-btn
              v-show="showInstallAndroid"
              id="installPrompt"
              text
              color="primary"
              width="100%"
              style="text-transform: capitalize;border-radius:5px;"
            >
              {{ $t('lang.install_application') }}
            </v-btn>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import { userLogin } from '@/api/auth'
import { notifications } from '@/utils/messages'
import '@/assets/flag-icon/css/flag-icon.css'

@Component({
  computed: {
    ...mapState('authentication', ['user']),
  },
  methods: {
    ...mapActions('authentication', [
      'login'
    ]),
    ...mapMutations('authentication', [
      'setUser'
    ]),
    ...mapMutations('app', [
      'toggleLoader'
    ])
  }
})
class Login extends Vue {
  
  data() {
    return {
      loginObj: {
        email: '',
        password: ''
      },
      rememberMe: false,
      lang: window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'no',
      promptObj: {},
      showInstallAndroid: false,
      showPassword: false,
      loading: false,
      valid: false,
      rules: {
        requiredValue: v => !!v || this.$t('lang.required'),
        emailMatch: (value) => /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(value) || this.$t('lang.invalid_email')
      }
    }
  }

  get listofLang () {
    return [{
      id: 'en',
      icon: 'flag-icon flag-icon-gb',
      name: this.$t("lang.english")
    }, {
      id: 'no',
      icon: 'flag-icon flag-icon-no',
      name: this.$t("lang.norwegian")
    }]
  } 
  
  created () {
    const rem = this.$cookie.get('_rememberMe')
    if (rem !== null) this.loginObj = JSON.parse(rem)
  }

  beforeMount() {
    if(this.user) this.$router.replace('/home')
  }

  mounted() {
    const lang = window.localStorage.getItem('lang')
    this.$i18n.locale = lang ? lang : 'no'
    this.$cookie.set('locale', this.$i18n.locale)
    this.showInstallPrompt()
  }

  /**
   * changeLang
   */
  changeLang() {
    this.$i18n.locale = this.lang
    window.localStorage.setItem('lang', this.lang)
  }

  /**
   * showInstallPrompt
   */
  showInstallPrompt() {
    const self = this
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      self.promptObj = e
      self.showInstallAndroid = true
    })

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }

    if (!isIos() && !this.isStandAloneAndroid) {
      /* Prompt user to install the app */
      const installBtn = document.getElementById('installPrompt')
      let isShown = false
      try {
        if (!isShown) {
          installBtn.addEventListener('click', () => {
            if (!isShown) {
              self.promptObj.prompt()
              self.promptObj.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the A2HS prompt')
                  isShown = true
                } else {
                  console.log('User dismissed the A2HS prompt')
                }
                self.promptObj = null
              })
            }
          })
        }
      } catch (ex) {
        isShown = true
      }
    } else this.showInstallAndroid = false

    // Detects if device is in standalone mode
    const isInStandaloneModeIos = () => ('standalone' in window.navigator) && (window.navigator.standalone)
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneModeIos()) this.showInstalliOS = true
    else this.showInstalliOS = false
  }

  /**
   * userLogin
   */
  handleUserLogin() {
    const date = new Date()
    date.setDate(date.getDate() + 4)
    this.valid = true
    this.toggleLoader()
    if (this.$refs.form.validate()) {
      userLogin(this.loginObj)
        .then(({ token, user }) => {
          // eslint-disable-next-line
          const { id, first_name, email } = user
          this.$cookie.set("authToken", token, { expires: date })
          // eslint-disable-next-line
          this.$cookie.set("user-details", JSON.stringify({ id, first_name, email }), { expires: date })
          if (this.rememberMe) this.$cookie.set('_rememberMe', JSON.stringify(this.loginObj),{ expires: '1Y' })
          // eslint-disable-next-line
          this.setUser(JSON.stringify({ id, first_name, email }))
          this.login({ apiToken: token, userDetails: { id, first_name, email } })
          this.$store.state.isLoggedIn = true
          if(token) {
            window.location.href='/'
            this.$router.replace('/home')
          }
        })
        .then(() => this.toggleLoader())
        .catch((err) => {
          notifications(this, this.$t("lang.auth_failed"), {
            type: 'error',
            position: 'top-right'
          })
          this.toggleLoader()
        })
    }
  }
}

export default Login

</script>

<style scoped>
  #login-container {
    text-align: center;
    background-color: #e25303;
    background-image: url('/img/beige_gradient.jpg');
    width: 100% !important;
    height: 50% !important;
  }
</style>
