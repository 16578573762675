import request from '@/utils/request'

const BASE_URL = process.env.VUE_APP_BASE_URL

/**
 * searchLiens
 * @param { String } query 
 */
const userLogin = (data) => {
  console.log(process.env.VUE_APP_BASE_URL)
  return request({
    url: `${BASE_URL}/login`,
    method: 'POST',
    data
  })
}

/**
 * searchLiens
 * @param { String } query 
 */
const userLogout = (data) => {
  return request({
    url: `${BASE_URL}/login`,
    method: 'POST',
    data
  })
}

const sendEmmail = (data) => {
  return request({
    url: `${BASE_URL}/password/email`,
    method: 'POST',
    data
  })
}

const checkPasswordLink = (data) => {
  return request({
    url: `${BASE_URL}/checkPasswordLink`,
    method: 'POST',
    data
  })
}

const resetPassword = (data) => {
  return request({
    url: `${BASE_URL}/password/reset`,
    method: 'POST',
    data
  })
}

export {
  userLogin,
  userLogout,
  sendEmmail,
  checkPasswordLink,
  resetPassword
}